<template>
  <CModal id="SendEmail" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <CAlert color="secondary">
      <CIcon name="cil-bell" /> 請將<a href="https://docs.google.com/spreadsheets/d/1b8Qb8mCBKROjhf4ejvWIRQl91oVpWSltZf40sNKvsi4/edit#gid=0" target="_blank" class="text-info">範例格式</a>內複製到您的試算表後，並將「<span class="font-weight-bold">qs-sheet-reader@ksong-eb5c0.iam.gserviceaccount.com</span>」此帳號以擁有者身份加入至使用者共用清單內，即可將網址貼入以下欄位並匯入至系統中。
      <br><br><span class="text-danger font-weight-bold">請注意！單次匯入最多匯入500筆資料，若您超過500筆資料請分批匯入。</span>
    </CAlert>
    <CInput :label="'試算表網址'" v-model="SheetURL" placeholder="網址格式為: https://docs.google.com/spreadsheets/d/<DocID>/edit#gid=<SheetID>" />
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Organization.BulkImportMember') + ' - ' +  $t('Organization.BulkImportMemberGoogleSheet')  }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="Import()" color="success" :disabled="SheetURL === '' || Loading">
          {{ $t('Global.Execute') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "SendEmail",
  props: ['Toggle'],
  data() {
    return {
      Submit: false,
      Loading: false,
      SheetURL: ''
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
    },
    async Import() {
      this.Loading = true
      this.$Progress.start()
      return this.$axios.post('/import/user/importMember', {
        SheetURL: this.SheetURL
      }, {
        timeout: 180000,
        headers: {
          'locale': this.$store.state.locale,
          'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$emit('ImportSuccess')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/ImportMemberSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Organization/ImportMemberFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
